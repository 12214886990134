import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
  query AllMenuItemsQuery {
    allContentfulMenuItem(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          price
          title
          description {
            description
          }
          mainImage {
            gatsbyImageData(aspectRatio: 1, placeholder: BLURRED, layout: FULL_WIDTH)
          }
          metadata {
            tags {
              contentful_id
              name
            }
          }
          options
        }
      }
    }
  }
`

const formatItemOptions = (options) => {
  if (!options) return []

  const formatted = options.map((option) => {
    const [title, price] = option.split(' +')
    
    return {
      price,
      title,
    }
  })

  return formatted
}

const groupMenuItems = (edges) => {
  const groups = {}

  if (!edges || !edges.length){
    return groups
  }

  edges.forEach(({ node }) => {
    const { id, description, mainImage, metadata, options, price, title } = node
    const { tags = [] } = metadata || {}

    const menuItem = {
      description: description?.description,
      id,
      mainImage,
      options: formatItemOptions(options),
      price,
      title,
    }

    tags.forEach((tag) => {
      const { contentful_id, name } = tag

      const groupKey = contentful_id
      if (!groups[groupKey]){
        groups[groupKey] = {
          key: groupKey,
          title: name,
          items: [],
        }
      }

      groups[groupKey].items.push(menuItem)
    })

  })

  return groups
}

function useGroupedMenuItems(){
  const data = useStaticQuery(query)
  const { allContentfulMenuItem } = data || {}
  const { edges } = allContentfulMenuItem || {}

  const menuItemGroups = groupMenuItems(edges)

  const sortedMenuGroups = Object.values(menuItemGroups).sort((groupA, groupB) => {
    const sortA = groupA.title
    const sortB = groupB.title

    return sortA > sortB ? 1 : -1
  })

  return {
    menuItemGroups,
    sortedMenuGroups,
  }
}

export default useGroupedMenuItems
