import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import ContentHolder from 'components/ContentHolder'

import * as styles from './styles.module.scss'

const MenuHeader = (props) => {
  const { prop } = props

  return (
    <div className={styles.root}>
      <ContentHolder className={styles.contentHolder}>
        <h1>Visual Menu</h1>

        <hr className={styles.divider} />

        <Button className={styles.button} href="/menu.pdf" target="_blank">
          Download Menu
        </Button>
      </ContentHolder>
    </div>
  )
}

MenuHeader.propTypes = {}

export default MenuHeader
