// extracted by mini-css-extract-plugin
export var root = "styles-module--root--33YgA";
export var imageHolder = "styles-module--image-holder--3VHOS";
export var image = "styles-module--image--2NEPj";
export var imageText = "styles-module--image-text--W1i7X";
export var cameraIcon = "styles-module--camera-icon--2wtQJ";
export var content = "styles-module--content--162F7";
export var title = "styles-module--title--u1Hwb";
export var description = "styles-module--description--AEFfA";
export var options = "styles-module--options--1AiWK";
export var optionTitle = "styles-module--option-title--2Fym_";
export var price = "styles-module--price--HOdim";