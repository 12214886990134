import React, { Fragment } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import ContentHolder from '../../../components/ContentHolder'

import * as styles from './styles.module.scss'

const MenuNavigation = (props) => {
  const { sortedMenuGroups = [] } = props
  const menuGroupCount = sortedMenuGroups.length

  return (
    <div className={styles.root}>
      <ContentHolder>
        <ul className={styles.navigation}>
          {sortedMenuGroups.map((menuGroup, index) => {
            const { key, title } = menuGroup
            const last = menuGroupCount === (index + 1)

            return (
              <Fragment key={key}>
                <li>
                  <AnchorLink to={`/menu#${key}`} title={title}>
                    {title}
                  </AnchorLink>
                </li>

                {!last && <li className={styles.divider}>|</li>}
              </Fragment>
            )
          })}
        </ul>
      </ContentHolder>
    </div>
  )
}

export default MenuNavigation
