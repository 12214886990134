import React from 'react'
import { Helmet } from 'react-helmet'

import useGroupedMenuItems from 'hooks/useGroupedMenuItems'

import ContentHolder from 'components/ContentHolder'

import Footer from './Footer'
import MenuGroup from './MenuGroup'
import MenuHeader from './MenuHeader'
import MenuNavigation from './MenuNavigation'

import * as styles from './styles.module.scss'

const Menu = () => {  
  const groupedMenuItemsPayload = useGroupedMenuItems()
  const { sortedMenuGroups } = groupedMenuItemsPayload

  return (
    <div className={styles.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Black Honey | Visual Menu</title>
      </Helmet>

      <MenuHeader />

      <MenuNavigation sortedMenuGroups={sortedMenuGroups} />

      <ContentHolder className={styles.contentHolder}>
        {sortedMenuGroups.map((menuGroup) => (
          <MenuGroup key={menuGroup.key} menuGroup={menuGroup} />
        ))}

        <p className={styles.disclaimer}>Gluten Free options available. Please speak to our friendly team.</p>
      </ContentHolder>

      <Footer />
    </div>
  )
}

export default Menu
