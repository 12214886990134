import React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import CameraIcon from 'images/camera-alt.svg'

import * as styles from './styles.module.scss'

const MenuItem = (props) => {
  const { menuItem } = props
  const { description, mainImage, options = [], price, title } = menuItem || {}

  return (
    <div className={styles.root}>
      <div className={styles.imageHolder}>
        {mainImage && (
          <GatsbyImage alt={title} className={styles.image} placeholder="blurred" image={getImage(mainImage)} />
        )}

        {!mainImage && (
          <>
            <StaticImage
              alt="No Image"
              aspectRatio="1"
              className={styles.image}
              layout="fullWidth"
              src="../../../../images/no-image.jpg"
            />

            <div className={styles.imageText}>
              <CameraIcon className={styles.cameraIcon} fill="white" width={40} height={40} />

              <span>Coming Soon</span>
            </div>
          </>
        )}
      </div>

      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>

        <p className={styles.description}>{description}</p>

        {!!options.length && (
          <ul className={styles.options}>
            {options.map(({ title, price }) => (
              <li key={title}>
                <span className={styles.optionTitle}>{title}</span>
                <span>+{price}</span>
              </li>
            ))}
          </ul>
        )}

        <div className={styles.price}>${price}</div>
      </div>
    </div>
  )
}

export default MenuItem
