import React from 'react'

import MenuItem from './MenuItem'

import * as styles from './styles.module.scss'

const MenuGroup = (props) => {
  const { menuGroup } = props
  const { key, items = [], title } = menuGroup || {}

  return (
    <div className={styles.root} id={key}>
      <h1 className={styles.title}>
        {title}
      </h1>

      <div className={styles.itemGrid}>
        {items.map((item) => (
          <MenuItem key={item.id} menuItem={item} />
        ))}
      </div>
    </div>
  )
}

export default MenuGroup
